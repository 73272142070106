const MILLIS_IN_ONE_SECOND = 1000;
const SECONDS_IN_ONE_MINUTE = 60;
const HOURS_IN_ONE_DAY = 24;
const MILLIS_IN_ONE_MINUTE = SECONDS_IN_ONE_MINUTE * MILLIS_IN_ONE_SECOND;
const MILLIS_IN_ONE_HOUR = SECONDS_IN_ONE_MINUTE * MILLIS_IN_ONE_MINUTE;
const MILLIS_IN_ONE_DAY = HOURS_IN_ONE_DAY * MILLIS_IN_ONE_HOUR;

export default class TimeDistance {

	private readonly ms: number;

	constructor(ms: number) {
		this.ms = ms;
	}

	static millis(ms: number): TimeDistance {
		return new TimeDistance(ms);
	}

	static seconds(s: number): TimeDistance {
		return new TimeDistance(MILLIS_IN_ONE_SECOND * s);
	}

	static minutes(m: number): TimeDistance {
		return new TimeDistance(MILLIS_IN_ONE_MINUTE * m);
	}

	static hours(h: number): TimeDistance {
		return new TimeDistance(MILLIS_IN_ONE_HOUR * h);
	}

	static days(d: number): TimeDistance {
		return new TimeDistance(MILLIS_IN_ONE_DAY * d);
	}


	inMilliseconds(): number {
		return this.ms;
	}

	inSeconds(): number {
		return this.ms / MILLIS_IN_ONE_SECOND;
	}

	inMinutes(): number {
		return this.ms / MILLIS_IN_ONE_MINUTE;
	}

	inHours(): number {
		return this.ms / MILLIS_IN_ONE_HOUR;
	}

	inDays(): number {
		return this.ms / MILLIS_IN_ONE_DAY;
	}

	isShorterThan(other: TimeDistance): boolean {
		return this.ms < other.inMilliseconds();
	}

	isShorterThanOrEqualTo(other: TimeDistance): boolean {
		return this.ms <= other.inMilliseconds();
	}

	add(other: TimeDistance): TimeDistance {
		return new TimeDistance(this.ms + other.inMilliseconds());
	}

	from(time: Date): Date {
		return new Date(time.getTime() + this.ms);
	}

	isNegative(): boolean {
		return this.ms < 0;
	}

	abs(): TimeDistance {
		if (this.isNegative()) {
			return new TimeDistance(-this.ms);
		} else {
			return this;
		}
	}

}


