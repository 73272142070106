import camelize from 'camelize-ts';

type JSON = string | number | boolean | null | { [key: string]: JSON } | JSON[];
export function data(el: HTMLElement, key: string): JSON {
	const val = el.dataset[key];

	if (val === undefined)
		return null;

	return camelize(JSON.parse(val));
}

export enum SizeType {
	INNER,
	OUTER,
	OFFSET,
	CLIENT
}

export function width(el: HTMLElement|Window, type = SizeType.INNER): number {
	if (el instanceof Window)
		return el.innerWidth;

	switch (type) {
		case SizeType.CLIENT:
			return el.clientWidth;
		case SizeType.OFFSET:
			return el.offsetWidth;
		case SizeType.INNER:
			return el.clientWidth - parseInt(window.getComputedStyle(el, null).getPropertyValue('padding-left')) - parseInt(window.getComputedStyle(el, null).getPropertyValue('padding-right'));
		case SizeType.OUTER:
			return el.offsetWidth + parseInt(window.getComputedStyle(el, null).getPropertyValue('margin-left')) + parseInt(window.getComputedStyle(el, null).getPropertyValue('margin-right'));
	}
}

export function height(el: HTMLElement|Window, type = SizeType.INNER): number {
	if (el instanceof Window)
		return el.innerHeight;

	switch (type) {
		case SizeType.CLIENT:
			return el.clientHeight;
		case SizeType.OFFSET:
			return el.offsetHeight;
		case SizeType.INNER: {
			const computedStyle = window.getComputedStyle(el, null);
			return el.clientHeight - parseInt(computedStyle.getPropertyValue('padding-top')) - parseInt(computedStyle.getPropertyValue('padding-bottom'));
		}
		case SizeType.OUTER: {
			const computedStyle = window.getComputedStyle(el, null);
			return el.offsetWidth + parseInt(computedStyle.getPropertyValue('margin-top')) + parseInt(computedStyle.getPropertyValue('margin-bottom'));
		}
	}
}

export function offset(el: HTMLElement): { top: number, left: number } {
	const rect = el.getBoundingClientRect();
	return {
		top: rect.top + window.scrollY,
		left: rect.left + window.scrollX
	}
}

export function outerHeight(el: HTMLElement): number {
  const style = getComputedStyle(el);
  return el.offsetHeight + parseInt(style.marginTop || style.margin) + parseInt(style.marginBottom || style.margin);
}
