import $ from '@/br-jquery';
import {language} from "@/current-page";

const renderBid = (amount: string, speed: string, textSize: string): string => `\
<div class="fullsize-overlay flex-column justify-center anim-${speed} anim-appear pointer-events-off">
	<div class="new-top-bid-amount flex-static ${textSize} text-center tw-py-2.5 nowrap">
		${amount}&nbsp;kr.
	</div>
</div>`

const DEFAULT_OPTIONS = {
	speed: 'slower',
	textSize: 'tw-text-lg small:tw-text-xl' // big text
};

// Show the "New Bid" animation
export function displayNewBid(container: HTMLElement | string | JQuery, amount: number, options:{ speed?: string, textSize?: string } = {}): void {
	const { speed, textSize } = { ...DEFAULT_OPTIONS, ...options }
	const $container = typeof container == 'string' ? $(container): $(container);

	const $el = $(renderBid(amount.toLocaleString(language()), speed, textSize))
	$el.one('animationend webkitAnimationEnd MSAnimationEnd oAnimationEnd', () => $el.remove());
	$el.appendTo($container);
	$container.find('.lot-price').css('opacity', 0);
}
