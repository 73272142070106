const KeyCodes = {
	ENTER: 13,
	ESCAPE: 27,
	ARROW_UP: 38,
	ARROW_DOWN: 40,
	ZERO: 48,
	NINE: 57
};

export default KeyCodes;
