const customerNumberElement = document.querySelector('meta[name=customer-number]');
const customerNumber = customerNumberElement && customerNumberElement.getAttribute('content');

const isLoggedIn = customerNumber != null;

const CurrentCustomer = {
	isLoggedIn: isLoggedIn,
	isAnonymous: !isLoggedIn,
	customerNumber: customerNumber
};

export default CurrentCustomer;
