import { EventEmitter2 } from 'eventemitter2';

const emitter = new EventEmitter2({
	wildcard: true,
});

const protocol = location.protocol === 'https:' ? 'wss://' : 'ws://';
const wsUrl =
	import.meta.env.VITE_LOT_EVENT_WS_URL ||
	`${protocol}${location.host}/websocket/live-lot/lot/events`;
let channel: WebSocket | null = null;
let reconnectTimeoutId: number;
let reconnectDelay: number;
reconnectDelay = 10;
const maxReconnectDelay = 5000;
let lotLastTimestampMap = new Map<string, number>();
// Time before a lot-event-timestamp can be pruned
let lotLastTimestampMapMaxAge = 10000;
let keepAliveIntervalId: number;
let pingIntervalIdId: number;

let lastKeepaliveIntervalCheck: number;

const connect = function () {
	if (import.meta.env.DEV && import.meta.env.VITE_LOT_EVENT_WS_URL == null) {
		console.warn('VITE_LOT_EVENT_WS_URL is not defined');
		return;
	}

	if (typeof WebSocket === 'undefined' || WebSocket === null) {
		console.error('WebSocket is not supported by this browser');
		return;
	}

	if (channel != null) {
		return;
	}

	console.debug('Connecting to ' + wsUrl + '...');
	channel = new WebSocket(wsUrl);

	channel.onerror = function (ev: Event) {
		return console.error(JSON.stringify(ev));
	};

	channel.onclose = function () {
		channel = null;
	};

	channel.onopen = function () {
		console.info('Channel to ' + wsUrl + ' is now open.');
		reconnectDelay = 10;

		console.groupCollapsed('Lot events connected');
		emitter.emit('connected');
		console.groupEnd();
	};

	channel.onmessage = function (event): void {
		const message = JSON.parse(event.data) as {
			lotId: string;
			timestamp: number;
			type: string;
		};
		const { lotId, timestamp, type } = message;
		const timestampKey = `${lotId}/${type}`;

		const lastTimestamp = lotLastTimestampMap.get(timestampKey);

		// Ignore messages that are older than the last message we received for a
		// given lot
		if (lastTimestamp && lastTimestamp >= timestamp) return;
		lotLastTimestampMap.set(timestampKey, timestamp);

		console.groupCollapsed('lot event:', message);
		emitter.emit(type, lotId, timestamp);
		console.groupEnd();
	};
};

const isConnected = function(): boolean {
	return channel?.readyState === WebSocket.OPEN;
}

const keepAlive = function (): void {
	cancelKeepalive()
	pingIntervalIdId = window.setInterval(() => {
			if (isConnected()) {
				channel?.send(JSON.stringify({ ping: true }))
			}
		},10000
	);

	keepAliveIntervalId = window.setInterval(() => {
		lastKeepaliveIntervalCheck = Date.now();
		if (!isConnected() && channel?.readyState !== WebSocket.CONNECTING) {
			console.warn(
				'WebSocket unexpectedly closed. Attempting to reconnect in ' +
				reconnectDelay +
				' ms.'
			);
			reconnectTimeoutId = window.setTimeout(
				connect.bind(channel),
				reconnectDelay
			);
			if (reconnectDelay < maxReconnectDelay) {
				reconnectDelay *= 2;
			}
		}
	}, 1000);
}

const cancelKeepalive = function(): void {
	clearInterval(keepAliveIntervalId);
	clearInterval(reconnectTimeoutId);
	clearInterval(pingIntervalIdId);
};


// avoid lotLastTimestampMap bloating during long-running sessions
setInterval(lotLastTimestampMapPruning, 60000);
async function lotLastTimestampMapPruning() {
	const currentTime = new Date().getTime();
	lotLastTimestampMap.forEach((storedTimestamp, storedEventId) => {
		if (currentTime - storedTimestamp > lotLastTimestampMapMaxAge) {
			lotLastTimestampMap.delete(storedEventId);
		}
	});
}

const disconnect = function (): void {
	cancelKeepalive()
	if (channel == null) {
		return;
	}

	channel.close();
};

if (!isConnected()) {
	connect();
	keepAlive();
}

// Prevent unexpected_disconnect from firing when the page is unloaded
window.onbeforeunload = function () {
	disconnect();
};

export default {
	on: emitter.on.bind(emitter),
	off: emitter.off.bind(emitter),
	disconnect,
	isConnected,
	lastKeepaliveIntervalCheck(): number {
		return lastKeepaliveIntervalCheck;
	},
	onConnected(cb: () => void): void {
		this.on('connected', cb);
		if (this.isConnected()) {
			cb();
		}
	},
};
