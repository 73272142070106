type Callback = () => void;

let listeners: Callback[] = [];

const notifyListeners = function() {
	if (document.visibilityState === 'visible') {
		listeners.forEach(l => {
			// Isolate from exceptions thrown
			setTimeout(l, 1);
		});
	}
};

document.addEventListener('visibilitychange', notifyListeners, false);
document.addEventListener('pagehide', notifyListeners, false);

export default {
	onAwaken: (listener: Callback): void => {
		listeners.push(listener);
	},
	off: (): void => {
		listeners = [];
	}
};
