import { language} from '@/current-page';

type Translations = { [key: string]: (string | Translations) }

type Currency = {
	name: string,
	format: string,
	symbol: string
	range_format: string
};

type TopLevelTranslations = {
	[key: string]: string | Translations,
	currencies: {
		currencies: Record<string, Currency>
	}
}

declare const I18n: TopLevelTranslations;

const logger = console;
const translations = I18n;
const placeholderPattern = /%\{([^{}]*)\}/g;

const supplant = function(s: string, o: Record<string, string | number>) {
	return s.replace(placeholderPattern, function(pattern: string, key: string): string {
		const replacement = o[key];
		if (typeof replacement === 'string')
			return replacement
		return replacement.toString()
	});
};

const humanize = (key: string) => key.slice(key.lastIndexOf('.') + 1).replace(/([a-z])([A-Z])/g, '$1 $2').replace('_', ' ').toLowerCase();

function lookup(key: string) : string | Translations | null {
	return key.split('.').reduce(((translations: string | Translations | null, part): string | Translations | null => {
		if (translations == null)
			return null;

		if (typeof translations === 'string')
			return translations;

		return translations[part];
	}), translations);
}

export const pluralize = function(count: number, key: string): string {

	const translation = lookup(key);

	if (translation == null || typeof translation === 'string') {
		logger.warn(JSON.stringify(translation) + ' is not a valid pluralization');
		return count + ' ' + humanize(key);
	}

	const {
		zero,
		one,
		other
	} = translation;

	if ((count === 0) && (typeof zero === 'string')) {
		return translation.zero as string;

	} else if (count === 1 && typeof one === "string") {
		return translation.one as string;

	} else if (typeof other === 'string'){
		return supplant(other, { count });
	}

	logger.warn('Missing pluralization for key "' + key + '"');
	return count + ' ' + humanize(key);
};

export const translate = function(key: string, options = {}): string {

	const translation = lookup(key);
	if (typeof translation === 'string')
		return supplant(translation, options);

	if (typeof translation === "object") {
		return JSON.stringify(translation);
	}

	logger.warn('Missing translation for key "' + key + '"');
	return humanize(key);
};

export const capitalizeFirstLetterOf = function(s: string) : string {
	if (s.length === 0) {
		return s;
	} else {
		return s[0].toUpperCase() + s.slice(1);
	}
};

export const localizedCurrencyAmount = (amount: number, currency: string, precision = 0) : string => {
	const currencyTranslations = I18n.currencies.currencies[currency];
	if (currencyTranslations == null)
		throw new Error(`Missing currency for ${currency}`);

	const {
		format,
		symbol
	} = currencyTranslations;

	return supplant(format, { amount: amount.toLocaleString(language(), { minimumFractionDigits: precision}), symbol });
}

export const localizedCurrencyRange = (from: number, to: number, currency: string) : string => {
	if (from === to)
		return localizedCurrencyAmount(from, currency);

	const currencyTranslations = I18n.currencies.currencies[currency];
	if (currencyTranslations == null)
		throw new Error(`Missing currency for ${currency}`);

	const {
		range_format,
		symbol
	} = currencyTranslations;

	return supplant(range_format, { from: from.toLocaleString(), to: to.toLocaleString(), symbol });
}

